import _implementation from "./implementation";
import * as _defineProperties2 from "define-properties";
var _defineProperties = _defineProperties2;
try {
  if ("default" in _defineProperties2) _defineProperties = _defineProperties2.default;
} catch (e) {}
var exports = {};
var implementation = _implementation;
var supportsDescriptors = _defineProperties.supportsDescriptors;
var $gOPD = Object.getOwnPropertyDescriptor;
exports = function getPolyfill() {
  if (supportsDescriptors && /a/mig.flags === "gim") {
    var descriptor = $gOPD(RegExp.prototype, "flags");
    if (descriptor && typeof descriptor.get === "function" && typeof RegExp.prototype.dotAll === "boolean" && typeof RegExp.prototype.hasIndices === "boolean") {
      /* eslint getter-return: 0 */
      var calls = "";
      var o = {};
      Object.defineProperty(o, "hasIndices", {
        get: function () {
          calls += "d";
        }
      });
      Object.defineProperty(o, "sticky", {
        get: function () {
          calls += "y";
        }
      });
      if (calls === "dy") {
        return descriptor.get;
      }
    }
  }
  return implementation;
};
export default exports;